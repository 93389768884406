import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import languages, { defaultLocale } from "../i18n"

export const Context = React.createContext( null )

const defaultMessages = languages[ defaultLocale ].messages

export default function Wrapper( props ) {
  const [ locale, setLocale ] = useState( defaultLocale )
  const [ messages, setMessages ] = useState( defaultMessages )

  const [ alertPopUp, setAlertPopUp ] = useState( false )

  function alertPopUpToggle(  ) {
    setAlertPopUp( prev => !prev )
  }

  useEffect( () => {
    const path = location.pathname
    if (  path.includes( 'am' ) ) {
      changeLanguage( 'am', true )
    }
  }, [] )

  function changeLanguage( newLocale, isFirst = false ) {
    setLocale( newLocale )
    const newMessages = languages[ newLocale ]?.messages || defaultMessages
    setMessages( newMessages )
    if ( !isFirst ) {
      window.history.pushState( "", "", `/${ newLocale === defaultLocale ? '' : newLocale }` )
    }
  }

  return (
    <Context.Provider value = { { locale, changeLanguage, alertPopUpToggle, alertPopUp } }>
      <IntlProvider
        locale={ locale }
        messages={ messages }
      >
        { props.children }
      </IntlProvider>
    </Context.Provider>
  )
}
