import { useIntl } from "react-intl"
import DocumentTitle from 'react-document-title'

import './App.scss'
import Header from "./components/Layout/Header"
import Footer from "./components/Layout/Footer"
import Content from "./components/Layout/Content"
import Background from "./components/Layout/Background"

function App() {
  const intl = useIntl()
  return (
    <DocumentTitle title={ intl.formatMessage( { id: 'HeaderTitle' } ) }>
      <div className="App">
        <Background/>
        <Header/>
        <Content/>
        <Footer/>
      </div>
    </DocumentTitle>
  )
}

export default App
