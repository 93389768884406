const CopyLogo = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="svgChild"
        d="M15 5H11V7H15C16.65 7 18 8.35 18 10C18 11.65 16.65 13 15 13H11V15H15C17.76 15 20 12.76 20 10C20 7.24 17.76 5 15 5ZM9 13H5C3.35 13 2 11.65 2 10C2 8.35 3.35 7 5 7H9V5H5C2.24 5 0 7.24 0 10C0 12.76 2.24 15 5 15H9V13ZM6 9H14V11H6V9Z"
        fill="#828282"
      />
    </svg> )
}

const FacebookLogo = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_353:1223)">
        <path
          className="svgChild"
          d="M13.3309 3.32083H15.1567V0.140833C14.8417 0.0975 13.7584 0 12.4967 0C9.86422 0 8.06088 1.65583 8.06088 4.69917V7.5H5.15588V11.055H8.06088V20H11.6226V11.0558H14.4101L14.8526 7.50083H11.6217V5.05167C11.6226 4.02417 11.8992 3.32083 13.3309 3.32083Z"
          fill="#828282"
        />
      </g>
      <defs>
        <clipPath id="clip0_353:1223">
          <rect
            fill="white"
            height="20"
            width="20"
          />
        </clipPath>
      </defs>
    </svg> )
}

const InstagramLogo = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_353:1225)">
        <path
          className="svgChild"
          d="M10.0032 4.86523C7.16742 4.86523 4.87158 7.16357 4.87158 9.9969C4.87158 12.8327 7.16992 15.1286 10.0032 15.1286C12.8391 15.1286 15.1349 12.8302 15.1349 9.9969C15.1349 7.16107 12.8366 4.86523 10.0032 4.86523ZM10.0032 13.3277C8.16242 13.3277 6.67242 11.8369 6.67242 9.9969C6.67242 8.1569 8.16325 6.66607 10.0032 6.66607C11.8432 6.66607 13.3341 8.1569 13.3341 9.9969C13.3349 11.8369 11.8441 13.3277 10.0032 13.3277Z"
          fill="#828282"
        />
        <path
          className="svgChild"
          d="M14.1233 0.0637988C12.2833 -0.0220346 7.72578 -0.0178679 5.88411 0.0637988C4.26578 0.139632 2.83828 0.530465 1.68745 1.6813C-0.235888 3.60463 0.00994524 6.1963 0.00994524 9.99713C0.00994524 13.8871 -0.206721 16.4188 1.68745 18.313C3.61828 20.243 6.24744 19.9905 10.0033 19.9905C13.8566 19.9905 15.1866 19.993 16.5491 19.4655C18.4016 18.7463 19.7999 17.0905 19.9366 14.1163C20.0233 12.2755 20.0183 7.7188 19.9366 5.87713C19.7716 2.3663 17.8874 0.237132 14.1233 0.0637988ZM17.0358 17.0405C15.7749 18.3013 14.0258 18.1888 9.97911 18.1888C5.81244 18.1888 4.14161 18.2505 2.92244 17.028C1.51828 15.6305 1.77244 13.3863 1.77244 9.9838C1.77244 5.37963 1.29995 2.0638 5.92078 1.82713C6.98244 1.78963 7.29494 1.77713 9.96744 1.77713L10.0049 1.80213C14.4458 1.80213 17.9299 1.33713 18.1391 5.95713C18.1866 7.0113 18.1974 7.32797 18.1974 9.9963C18.1966 14.1146 18.2749 15.7955 17.0358 17.0405Z"
          fill="#828282"
        />
        <path
          className="svgChild"
          d="M15.3381 5.8622C16.0004 5.8622 16.5372 5.32531 16.5372 4.66303C16.5372 4.00075 16.0004 3.46387 15.3381 3.46387C14.6758 3.46387 14.1389 4.00075 14.1389 4.66303C14.1389 5.32531 14.6758 5.8622 15.3381 5.8622Z"
          fill="#828282"
        />
      </g>
      <defs>
        <clipPath id="clip0_353:1225">
          <rect
            fill="white"
            height="20"
            width="20"
          />
        </clipPath>
      </defs>
    </svg> )
}

const TwitterLogo = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="svgChild"
        d="M20 3.53707C19.2642 3.85522 18.4733 4.06948 17.6433 4.16606C18.4908 3.67179 19.1417 2.8886 19.4475 1.95526C18.655 2.413 17.7767 2.74576 16.8417 2.92512C16.0942 2.14842 15.0267 1.66309 13.8467 1.66309C11.1975 1.66309 9.25083 4.07029 9.84917 6.5692C6.44 6.40283 3.41667 4.81209 1.3925 2.39434C0.3175 4.19041 0.835 6.53999 2.66167 7.72979C1.99 7.70869 1.35667 7.52933 0.804167 7.22985C0.759167 9.08111 2.12167 10.8131 4.095 11.1986C3.5175 11.3511 2.885 11.3869 2.24167 11.2667C2.76333 12.8542 4.27833 14.0091 6.075 14.0416C4.35 15.3588 2.17667 15.9472 0 15.6973C1.81583 16.8311 3.97333 17.4925 6.29 17.4925C13.9083 17.4925 18.2125 11.2262 17.9525 5.60584C18.7542 5.04177 19.45 4.33812 20 3.53707Z"
        fill="#828282"
      />
    </svg> )
}

const iconsList = {
  facebook: {
    component: <FacebookLogo/>,
    alt: "Facebook logo",
    shareURL: "https://www.facebook.com/sharer.php?u=",
  },
  twitter: {
    component: <TwitterLogo/>,
    alt: "Twitter logo",
    shareURL: "https://twitter.com/share?url=",
  },
  instagram: {
    component: <InstagramLogo/>,
    alt: "Instagram logo",
  },
  copy: {
    component: <CopyLogo/>,
    alt: "Copy logo",
  },
}

export default iconsList
