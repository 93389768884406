import React, { useMemo } from 'react'
import { Context as LanguageContext } from "../../../services/Wrapper"
import { contextTranslater } from "../../../services/helpers"

function VideoComponent( { data, scrollable, componentClassName } ) {

  const { locale } = React.useContext( LanguageContext )
  const { description, src, type, className, styles } = data
  const iframeProps = useMemo( () => {
    return type === 'youtube' ? {
      allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      allowFullScreen: true,
      className: "w-full h-52 xm:h-48 sm:h-52 md:h-64 lg:h-80 xl:h-92",
      frameBorder: "0",
      title: "YouTube video player",
    } : {
      className: "w-full h-52 xm:h-48 sm:h-52 md:h-64 lg:h-80 xl:h-92",
      frameBorder: "no",
      scrolling: "no",
    }
  }, [ type ] )

  return (
    <div
      className={ `${ scrollable ? '' : 'sticky' } tracking-widest w-full xm:my-8 lg:my-14 ${ className || '' } ${ componentClassName }` }
      style={ styles || {} }
    >
      <iframe
        { ...iframeProps }
        src={ src }
      />
      <div className="mt-3 text-sm text-gray">
        <p>{ contextTranslater( locale, description ) }</p>
      </div>
    </div>
  )
}

export default VideoComponent
